import { Skeleton, Table, TableProps, Transition } from '@mantine/core';
import classNames from 'classnames';
import { BareCard } from './Card';
import NoDataCard from './noDataCard';

const TableComp = ({
    body = [],
    headers,
    maxHeight = 500,
    className,
    minHeight = 200,
    maxWidth,
    loading,
    dataCondition,
    headerClassName,
    hideScroll,
    noDataText,
    loader,
    spaceportBareCardClassName,
    bodyClassName,
    ...rest
}: {
    headers?: JSX.Element[] | JSX.Element;
    body: JSX.Element | JSX.Element[];
    maxHeight?: number | string;
    minHeight?: number | string;
    loading?: boolean;
    dataCondition?: boolean;
    maxWidth?: number | string;
    headerClassName?: string;
    spaceportBareCardClassName?: string;
    bodyClassName?: string;
    hideScroll?: boolean;
    noDataText?: string;
    loader?: JSX.Element;
} & TableProps) => (
    <Table
        className={classNames(
            'text-sm capitalize text-center mb-auto !border-border-color',
            className,
        )}
        {...rest}
        classNames={{
            tr: 'text-sm capitalize text-center !border-primary-10',
            td: 'text-sm capitalize text-center !border-primary-10',
            tbody: 'text-sm capitalize text-center !border-primary-10',
        }}
        styles={rest.styles}
        stickyHeader={typeof headers !== 'undefined'}
        style={{ ...rest.style, maxHeight }}
    >
        {typeof headers !== 'undefined' && (
            <Table.Thead
                className={classNames(
                    '!backdrop-blur-sm gradient-bg-hex -top-0 !h-16 text-navy-solid-30',
                    headerClassName,
                )}
            >
                {Array.isArray(headers) ? (
                    <Table.Tr className="mb-2 whitespace-pre-wrap border-inherit pb-2 text-primary-50">
                        {headers.map(key => key)}
                    </Table.Tr>
                ) : (
                    headers
                )}
            </Table.Thead>
        )}
        {Array.isArray(body) ? <Table.Tbody>{body.map(row => row)}</Table.Tbody> : body}
    </Table>
);

function StyledTable({
    body = [],
    headers,
    maxHeight = 500,
    className,
    minHeight = 200,
    maxWidth,
    loading,
    dataCondition,
    headerClassName,
    hideScroll,
    noDataText,
    loader,
    spaceportBareCardClassName,
    bodyClassName,
    justTable,
    ...rest
}: {
    headers?: JSX.Element[] | JSX.Element;
    body: JSX.Element | JSX.Element[];
    maxHeight?: number | string;
    minHeight?: number | string;
    loading?: boolean;
    dataCondition?: boolean;
    maxWidth?: number | string;
    headerClassName?: string;
    spaceportBareCardClassName?: string;
    bodyClassName?: string;
    hideScroll?: boolean;
    justTable?: boolean;
    noDataText?: string;
    loader?: JSX.Element;
} & TableProps) {
    if (loading) {
        return (
            <div
                className="mb-1 flex w-full items-center justify-center"
                style={{ maxHeight, minHeight, maxWidth }}
            >
                {loader ? (
                    <div
                        className="flex size-full items-center justify-center align-middle"
                        style={{ maxHeight, minHeight }}
                    >
                        {loader}
                    </div>
                ) : (
                    <Skeleton className="size-full" style={{ maxHeight, minHeight }} />
                )}
            </div>
        );
    }
    if (dataCondition) {
        return (
            <div
                className="mb-1 flex w-full items-center justify-center"
                style={{ maxHeight, minHeight, maxWidth }}
            >
                <NoDataCard>{noDataText || 'No Data Found'}</NoDataCard>
            </div>
        );
    }
    if (justTable)
        return (
            <div
                className={classNames(
                    'flex justify-start items-center text-sm h-fit capitalize mb-1 max-w-full w-full !overflow-auto !p-0',
                    { hideScroll },
                    spaceportBareCardClassName,
                )}
                style={{ maxHeight, minHeight, maxWidth }}
            >
                <TableComp
                    body={body}
                    headers={headers}
                    maxHeight={maxHeight}
                    className={className}
                    minHeight={minHeight}
                    maxWidth={maxWidth}
                    loading={loading}
                    dataCondition={dataCondition}
                    headerClassName={headerClassName}
                    hideScroll={hideScroll}
                    noDataText={noDataText}
                    loader={loader}
                    spaceportBareCardClassName={spaceportBareCardClassName}
                    bodyClassName={bodyClassName}
                    {...rest}
                />
            </div>
        );
    return (
        <BareCard
            className={classNames(
                'flex justify-start items-center text-sm h-fit capitalize mb-1 max-w-full w-full !overflow-auto !p-0',
                { hideScroll },
                spaceportBareCardClassName,
            )}
            style={{ maxHeight, minHeight, maxWidth }}
        >
            <TableComp
                body={body}
                headers={headers}
                maxHeight={maxHeight}
                className={className}
                minHeight={minHeight}
                maxWidth={maxWidth}
                loading={loading}
                dataCondition={dataCondition}
                headerClassName={headerClassName}
                hideScroll={hideScroll}
                noDataText={noDataText}
                loader={loader}
                spaceportBareCardClassName={spaceportBareCardClassName}
                bodyClassName={bodyClassName}
                {...rest}
            />
        </BareCard>
    );
}

export default StyledTable;
