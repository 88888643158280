export const READ_SNIPPET = `
from arena_integrations.data_upload import ArenaDataUploader

data_uploader = ArenaDataUploader(
    client_id=...,
    client_secret=...
)
data_uploader.upload_data(dataset_name: str, dataset: pd.DataFrame)
`;
export const READ_SNIPPET_MODEL = `
from arena_integrations.data_upload import ArenaDataUploader

data_uploader = ArenaDataUploader(
    client_id=...,
    client_secret=...
)
data_uploader.upload_model(model_name: str, model: pd.DataFrame)
`;

export const COLUMNS = ['DATASET NAME', 'INGESTED TIME', 'STATUS', '# OF RECORDS'];
export const OUTPUT_COLUMNS = ['MODEL NAME', 'INGESTED TIME', 'STATUS', 'USE CASE', '# OF RECORDS'];
export const SYNC_TITLES = {
    TARGET: 'ABI GHQ Azure Data Lake',
    DATASET: 'Order Completed (Segment Clickstream Orders)',
    'FILE PREFIX PATH': 'sv/clickstream/orders',
};

export const DATASETS = {
    items: [
        {
            name: 'Orders',
            creator: 'chetan@arena-ai.com',
            status: true,
            dataset_class: 'pq',
            documentation: 'Dataset Orders, extracted from Segment',
            tags: [
                {
                    country: 'CH',
                },
                {
                    branch: 'production',
                },
                {
                    repo: 'Arena Data Engineering',
                },
            ],
            config: {
                data_schema: {
                    schema_type: 'dataframe',
                    version: '1.0.0',
                    columns: {
                        ts: {
                            dtype: 'timestamp',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        sku: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        price: {
                            dtype: 'float32',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        poc_code: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        quantity: {
                            dtype: 'int',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        recommendation_type: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                    },
                    unique: null,
                    checks: null,
                    coerce: false,
                    strict: false,
                },
                bucket: 'arena-bert',
                key: 'Recommenders/SV/master/events/order_completed/',
            },
            size: '585K',
            records: '157',
        },
        {
            name: 'Line Item',
            creator: 'chetan@arena-ai.com',
            status: true,
            dataset_class: 'pq',
            documentation: 'Dataset Line Item, extracted from Segment',
            tags: [
                {
                    country: 'CH',
                },
                {
                    branch: 'production',
                },
                {
                    repo: 'Arena Data Engineering',
                },
            ],
            config: {
                data_schema: {
                    schema_type: 'dataframe',
                    version: '1.0.0',
                    columns: {
                        ts: {
                            dtype: 'timestamp',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        sku: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        price: {
                            dtype: 'float32',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        poc_code: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        quantity: {
                            dtype: 'int',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        recommendation_type: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                    },
                    unique: null,
                    checks: null,
                    coerce: false,
                    strict: false,
                },
                bucket: 'arena-bert',
                key: 'Recommenders/SV/master/events/card_viewed/',
            },
            size: '576K',
            records: '133',
        },
        {
            name: 'Store',
            creator: 'chetan@arena-ai.com',
            status: false,
            dataset_class: 'pq',
            documentation: 'Dataset Store, extracted from Segment',
            tags: [
                {
                    country: 'CH',
                },
                {
                    branch: 'production',
                },
                {
                    repo: 'Arena Data Engineering',
                },
            ],
            config: {
                data_schema: {
                    schema_type: 'dataframe',
                    version: '1.0.0',
                    columns: {
                        ts: {
                            dtype: 'timestamp',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        sku: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        price: {
                            dtype: 'float32',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        poc_code: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        quantity: {
                            dtype: 'int',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        recommendation_type: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                    },
                    unique: null,
                    checks: null,
                    coerce: false,
                    strict: false,
                },
                bucket: 'arena-bert',
                key: 'Recommenders/SV/master/events/card_added/',
            },
            size: '389K',
            records: '148',
        },
        {
            name: 'Orders',
            creator: 'chetan@arena-ai.com',
            status: true,
            dataset_class: 'pq',
            documentation: 'Dataset Orders, extracted from Segment',
            tags: [
                {
                    country: 'US',
                },
                {
                    branch: 'production',
                },
                {
                    repo: 'Arena Data Engineering',
                },
            ],
            config: {
                data_schema: {
                    schema_type: 'dataframe',
                    version: '1.0.0',
                    columns: {
                        ts: {
                            dtype: 'timestamp',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        sku: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        price: {
                            dtype: 'float32',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        poc_code: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        quantity: {
                            dtype: 'int',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        recommendation_type: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                    },
                    unique: null,
                    checks: null,
                    coerce: false,
                    strict: false,
                },
                bucket: 'arena-bert',
                key: 'Recommenders/AR/master/events/order_completed/',
            },
            size: '480K',
            records: '196',
        },
        {
            name: 'Line Item',
            creator: 'chetan@arena-ai.com',
            status: false,
            dataset_class: 'pq',
            documentation: 'Dataset Line Item, extracted from Segment',
            tags: [
                {
                    country: 'US',
                },
                {
                    branch: 'production',
                },
                {
                    repo: 'Arena Data Engineering',
                },
            ],
            config: {
                data_schema: {
                    schema_type: 'dataframe',
                    version: '1.0.0',
                    columns: {
                        ts: {
                            dtype: 'timestamp',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        sku: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        price: {
                            dtype: 'float32',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        poc_code: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        quantity: {
                            dtype: 'int',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        recommendation_type: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                    },
                    unique: null,
                    checks: null,
                    coerce: false,
                    strict: false,
                },
                bucket: 'arena-bert',
                key: 'Recommenders/AR/master/events/card_viewed/',
            },
            size: '420K',
            records: '167',
        },
        {
            name: 'Store',
            creator: 'chetan@arena-ai.com',
            status: true,
            dataset_class: 'pq',
            documentation: 'Dataset Store, extracted from Segment',
            tags: [
                {
                    country: 'US',
                },
                {
                    branch: 'production',
                },
                {
                    repo: 'Arena Data Engineering',
                },
            ],
            config: {
                data_schema: {
                    schema_type: 'dataframe',
                    version: '1.0.0',
                    columns: {
                        ts: {
                            dtype: 'timestamp',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        sku: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        price: {
                            dtype: 'float32',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        poc_code: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        quantity: {
                            dtype: 'int',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        recommendation_type: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                    },
                    unique: null,
                    checks: null,
                    coerce: false,
                    strict: false,
                },
                bucket: 'arena-bert',
                key: 'Recommenders/AR/master/events/card_added/',
            },
            size: '467K',
            records: '162',
        },
        {
            name: 'Orders',
            creator: 'chetan@arena-ai.com',
            status: true,
            dataset_class: 'pq',
            documentation: 'Dataset Orders, extracted from Segment',
            tags: [
                {
                    country: 'ES',
                },
                {
                    branch: 'production',
                },
                {
                    repo: 'Arena Data Engineering',
                },
            ],
            config: {
                data_schema: {
                    schema_type: 'dataframe',
                    version: '1.0.0',
                    columns: {
                        ts: {
                            dtype: 'timestamp',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        sku: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        price: {
                            dtype: 'float32',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        poc_code: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        quantity: {
                            dtype: 'int',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        recommendation_type: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                    },
                    unique: null,
                    checks: null,
                    coerce: false,
                    strict: false,
                },
                bucket: 'arena-bert',
                key: 'Recommenders/BR/master/events/order_completed/',
            },
            size: '535K',
            records: '181',
        },
        {
            name: 'Line Item',
            creator: 'chetan@arena-ai.com',
            status: false,
            dataset_class: 'pq',
            documentation: 'Dataset Line Item, extracted from Segment',
            tags: [
                {
                    country: 'ES',
                },
                {
                    branch: 'production',
                },
                {
                    repo: 'Arena Data Engineering',
                },
            ],
            config: {
                data_schema: {
                    schema_type: 'dataframe',
                    version: '1.0.0',
                    columns: {
                        ts: {
                            dtype: 'timestamp',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        sku: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        price: {
                            dtype: 'float32',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        poc_code: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        quantity: {
                            dtype: 'int',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        recommendation_type: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                    },
                    unique: null,
                    checks: null,
                    coerce: false,
                    strict: false,
                },
                bucket: 'arena-bert',
                key: 'Recommenders/BR/master/events/card_viewed/',
            },
            size: '495K',
            records: '125',
        },
        {
            name: 'Store',
            creator: 'chetan@arena-ai.com',
            status: true,
            dataset_class: 'pq',
            documentation: 'Dataset Store, extracted from Segment',
            tags: [
                {
                    country: 'ES',
                },
                {
                    branch: 'production',
                },
                {
                    repo: 'Arena Data Engineering',
                },
            ],
            config: {
                data_schema: {
                    schema_type: 'dataframe',
                    version: '1.0.0',
                    columns: {
                        ts: {
                            dtype: 'timestamp',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        sku: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        price: {
                            dtype: 'float32',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        poc_code: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        quantity: {
                            dtype: 'int',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        recommendation_type: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                    },
                    unique: null,
                    checks: null,
                    coerce: false,
                    strict: false,
                },
                bucket: 'arena-bert',
                key: 'Recommenders/BR/master/events/card_added/',
            },
            size: '349K',
            records: '141',
        },
        {
            name: 'Orders',
            creator: 'chetan@arena-ai.com',
            status: true,
            dataset_class: 'pq',
            documentation: 'Dataset Orders, extracted from Segment',
            tags: [
                {
                    country: 'DE',
                },
                {
                    branch: 'production',
                },
                {
                    repo: 'Arena Data Engineering',
                },
            ],
            config: {
                data_schema: {
                    schema_type: 'dataframe',
                    version: '1.0.0',
                    columns: {
                        ts: {
                            dtype: 'timestamp',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        sku: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        price: {
                            dtype: 'float32',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        poc_code: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        quantity: {
                            dtype: 'int',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        recommendation_type: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                    },
                    unique: null,
                    checks: null,
                    coerce: false,
                    strict: false,
                },
                bucket: 'arena-bert',
                key: 'Recommenders/DO/master/events/order_completed/',
            },
            size: '433K',
            records: '139',
        },
        {
            name: 'Line Item',
            creator: 'chetan@arena-ai.com',
            status: true,
            dataset_class: 'pq',
            documentation: 'Dataset Line Item, extracted from Segment',
            tags: [
                {
                    country: 'DE',
                },
                {
                    branch: 'production',
                },
                {
                    repo: 'Arena Data Engineering',
                },
            ],
            config: {
                data_schema: {
                    schema_type: 'dataframe',
                    version: '1.0.0',
                    columns: {
                        ts: {
                            dtype: 'timestamp',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        sku: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        price: {
                            dtype: 'float32',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        poc_code: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        quantity: {
                            dtype: 'int',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        recommendation_type: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                    },
                    unique: null,
                    checks: null,
                    coerce: false,
                    strict: false,
                },
                bucket: 'arena-bert',
                key: 'Recommenders/DO/master/events/card_viewed/',
            },
            size: '500K',
            records: '157',
        },
        {
            name: 'Store',
            creator: 'chetan@arena-ai.com',
            status: false,
            dataset_class: 'pq',
            documentation: 'Dataset Store, extracted from Segment',
            tags: [
                {
                    country: 'DE',
                },
                {
                    branch: 'production',
                },
                {
                    repo: 'Arena Data Engineering',
                },
            ],
            config: {
                data_schema: {
                    schema_type: 'dataframe',
                    version: '1.0.0',
                    columns: {
                        ts: {
                            dtype: 'timestamp',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        sku: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        price: {
                            dtype: 'float32',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        poc_code: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        quantity: {
                            dtype: 'int',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        recommendation_type: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                    },
                    unique: null,
                    checks: null,
                    coerce: false,
                    strict: false,
                },
                bucket: 'arena-bert',
                key: 'Recommenders/DO/master/events/card_added/',
            },
            size: '397K',
            records: '161',
        },
        {
            name: 'Orders',
            creator: 'chetan@arena-ai.com',
            status: true,
            dataset_class: 'pq',
            documentation: 'Dataset Orders, extracted from Segment',
            tags: [
                {
                    country: 'US',
                },
                {
                    branch: 'production',
                },
                {
                    repo: 'Arena Data Engineering',
                },
            ],
            config: {
                data_schema: {
                    schema_type: 'dataframe',
                    version: '1.0.0',
                    columns: {
                        ts: {
                            dtype: 'timestamp',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        sku: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        price: {
                            dtype: 'float32',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        poc_code: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        quantity: {
                            dtype: 'int',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        recommendation_type: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                    },
                    unique: null,
                    checks: null,
                    coerce: false,
                    strict: false,
                },
                bucket: 'arena-bert',
                key: 'Recommenders/PY/master/events/order_completed/',
            },
            size: '413K',
            records: '104',
        },
        {
            name: 'Line Item',
            creator: 'chetan@arena-ai.com',
            status: true,
            dataset_class: 'pq',
            documentation: 'Dataset Line Item, extracted from Segment',
            tags: [
                {
                    country: 'US',
                },
                {
                    branch: 'production',
                },
                {
                    repo: 'Arena Data Engineering',
                },
            ],
            config: {
                data_schema: {
                    schema_type: 'dataframe',
                    version: '1.0.0',
                    columns: {
                        ts: {
                            dtype: 'timestamp',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        sku: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        price: {
                            dtype: 'float32',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        poc_code: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        quantity: {
                            dtype: 'int',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        recommendation_type: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                    },
                    unique: null,
                    checks: null,
                    coerce: false,
                    strict: false,
                },
                bucket: 'arena-bert',
                key: 'Recommenders/PY/master/events/card_viewed/',
            },
            size: '379K',
            records: '185',
        },
        {
            name: 'Store',
            creator: 'chetan@arena-ai.com',
            status: false,
            dataset_class: 'pq',
            documentation: 'Dataset Store, extracted from Segment',
            tags: [
                {
                    country: 'US',
                },
                {
                    branch: 'production',
                },
                {
                    repo: 'Arena Data Engineering',
                },
            ],
            config: {
                data_schema: {
                    schema_type: 'dataframe',
                    version: '1.0.0',
                    columns: {
                        ts: {
                            dtype: 'timestamp',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        sku: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        price: {
                            dtype: 'float32',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        poc_code: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        quantity: {
                            dtype: 'int',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        recommendation_type: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                    },
                    unique: null,
                    checks: null,
                    coerce: false,
                    strict: false,
                },
                bucket: 'arena-bert',
                key: 'Recommenders/PY/master/events/card_added/',
            },
            size: '373K',
            records: '119',
        },
        {
            name: 'Orders',
            creator: 'chetan@arena-ai.com',
            status: true,
            dataset_class: 'pq',
            documentation: 'Dataset Orders, extracted from Segment',
            tags: [
                {
                    country: 'US',
                },
                {
                    branch: 'production',
                },
                {
                    repo: 'Arena Data Engineering',
                },
            ],
            config: {
                data_schema: {
                    schema_type: 'dataframe',
                    version: '1.0.0',
                    columns: {
                        ts: {
                            dtype: 'timestamp',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        sku: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        price: {
                            dtype: 'float32',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        poc_code: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        quantity: {
                            dtype: 'int',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        recommendation_type: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                    },
                    unique: null,
                    checks: null,
                    coerce: false,
                    strict: false,
                },
                bucket: 'arena-bert',
                key: 'Recommenders/MX/master/events/order_completed/',
            },
            size: '253K',
            records: '127',
        },
        {
            name: 'Line Item',
            creator: 'chetan@arena-ai.com',
            status: true,
            dataset_class: 'pq',
            documentation: 'Dataset Line Item, extracted from Segment',
            tags: [
                {
                    country: 'CH',
                },
                {
                    branch: 'production',
                },
                {
                    repo: 'Arena Data Engineering',
                },
            ],
            config: {
                data_schema: {
                    schema_type: 'dataframe',
                    version: '1.0.0',
                    columns: {
                        ts: {
                            dtype: 'timestamp',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        sku: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        price: {
                            dtype: 'float32',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        poc_code: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        quantity: {
                            dtype: 'int',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        recommendation_type: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                    },
                    unique: null,
                    checks: null,
                    coerce: false,
                    strict: false,
                },
                bucket: 'arena-bert',
                key: 'Recommenders/MX/master/events/card_viewed/',
            },
            size: '293K',
            records: '140',
        },
        {
            name: 'Store',
            creator: 'chetan@arena-ai.com',
            status: true,
            dataset_class: 'pq',
            documentation: 'Dataset Store, extracted from Segment',
            tags: [
                {
                    country: 'CH',
                },
                {
                    branch: 'production',
                },
                {
                    repo: 'Arena Data Engineering',
                },
            ],
            config: {
                data_schema: {
                    schema_type: 'dataframe',
                    version: '1.0.0',
                    columns: {
                        ts: {
                            dtype: 'timestamp',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        sku: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        price: {
                            dtype: 'float32',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        poc_code: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        quantity: {
                            dtype: 'int',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                        recommendation_type: {
                            dtype: 'string',
                            nullable: false,
                            checks: null,
                            unique: false,
                            coerce: false,
                            required: true,
                        },
                    },
                    unique: null,
                    checks: null,
                    coerce: false,
                    strict: false,
                },
                bucket: 'arena-bert',
                key: 'Recommenders/MX/master/events/card_added/',
            },
            size: '554K',
            records: '153',
        },
    ],
    count: 27,
};
